<template>
  <v-container class="d-flex flex-column pa-8" style="max-width: 1120px">
    <div class="header-top my-5">
      <h2 class="view-title">Auto Registration</h2>
    </div>
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="first_name"
                label="First Name"
                outlined
                placeholder="First Name"
                hint="First Name"
                hide-details="auto"
                :rules="[validators.required]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="last_name"
                label="Last Name"
                outlined
                placeholder="Last Name"
                hint="Last Name"
                hide-details="auto"
                :rules="[validators.required]"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="email"
                outlined
                type="email"
                label="Email"
                placeholder="Email"
                hide-details
                :rules="[validators.required, validators.emailValidator]"
              />
            </v-col>
          </v-row>

          <div class="mt-4 mb-2">
            <v-checkbox v-model="subscribe" hide-details class="mt-1">
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">
                    Check to get notified of our launch and receive exclusive updates on the product roadmap, deals,
                    tips, and how-tos.
                  </span>
                </div>
              </template>
            </v-checkbox>
          </div>

          <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loading">
            <span v-if="loading === false">Submit</span>
            <span v-else>
              <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
              <span>Sending request...</span>
            </span>
          </v-btn>

          <v-alert v-if="errors" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import store from '@/store'
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'
import { required, urlValidator, emailValidator } from '@core/utils/validation'

export default {
  setup(props) {
    const loading = ref(false)
    const errors = ref(null)
    const form = ref(null)

    const first_name = ref('')
    const last_name = ref('')
    const email = ref('')

    const subscribe = ref(false)

    // store methods
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }

    const submit = async () => {
      const check = form.value.validate()
      if (!check) {
        return
      }

      console.log(subscribe)

      const data = {
        first_name: first_name.value,
        last_name: last_name.value,
        email: email.value,
        subscribe: subscribe.value,
      }

      loading.value = true
      return await store
        .dispatch('auth/autoRegister', data)
        .then(async resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              await setSnackbar({
                status: true,
                text: resp.data.message,
                color: 'primary',
              })
              errors.value = null
            }
          }
        })
        .catch(error => {
          errors.value = error.response.data.errors
          console.log(errors.value)
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      errors,
      form,

      first_name,
      last_name,
      email,
      subscribe,

      validators: {
        required,
        urlValidator,
        emailValidator,
      },

      submit,
    }
  },
}
</script>

<style scoped>
.header-top {
  margin-bottom: 20px;
}
.btn-more {
  padding: 0px !important;
  word-break: break-word !important;
}

.btn-submit {
  margin-top: 20px;
}
</style>
